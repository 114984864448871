<template>
	<div class="mark-edit">
		<Nav></Nav>
		<el-form :model="article" :rules="rules" ref="article" label-position="top" label-width="100px" class="demo-ruleForm mark-edit-form">
			<el-form-item label="文章标题" prop="title">
				<el-input v-model="article.title"></el-input>
			</el-form-item>
			<el-form-item label="文章封面" prop="topImg">
				<el-input v-model="article.topImg"></el-input>
			</el-form-item>
			<el-form-item label="文章分类" prop="tid" class="article-type">
				<el-select v-model="article.tid" placeholder="请选择分类">
				  <el-option :label="type.type" :value="type.tid" v-for="(type,index) in types" :key='index'></el-option>
				</el-select>
			</el-form-item>
			<el-tag
			  v-for="tag in tags"
			  :key="tag.name"
			  closable
			  @close="handleClose(tag)">
			  {{tag}}
			</el-tag>
			<el-input
			  class="input-new-tag"
			  v-if="inputVisible"
			  v-model="inputValue"
			  ref="saveTagInput"
			  size="small"
			  @keyup.enter.native="handleInputConfirm"
			  @blur="handleInputConfirm"
			>
			</el-input>
			<el-button v-else class="button-new-tag" size="small" @click="showInput">+ New Tag</el-button>
			<el-form-item label="文章描述" prop="description">
			    <el-input type="textarea" v-model="article.description" class="textarea-desc"></el-input>
			  </el-form-item>
			<mavon-editor v-model="content" ref='md' :ishljs = "true" class="mavonEditor markdown-body"/>
			<el-form-item label="置顶文章" class="article-btn">
			    <el-switch v-model="article.pin"></el-switch>
			</el-form-item>
			<el-form-item label="开启评论" class="article-btn">
			    <el-switch v-model="article.commentable"></el-switch>
			</el-form-item>
			<el-form-item label="定时发送" class="article-btn">
			    <el-switch v-model="article.timing"></el-switch>
			</el-form-item>
			<el-date-picker
			      v-model="article.createTime"
			      type="datetime"
			      placeholder="选择日期时间"
			      default-time="12:00:00"
				  v-show="article.timing">
			</el-date-picker>
			<el-form-item class="article-submit-btn">
				<el-button type="primary" @click="updateForm('article')" :disabled='edit'>保存文章</el-button>
			    <el-button type="primary" @click="submitForm('article')" :disabled='!edit'>发布文章</el-button>
			</el-form-item>
		</el-form>
	</div>
</template>

<script>
import Nav from '@/components/Nav.vue'

export default {
	components: {
		Nav
	},
	data() {
		return {
			content: '',
			edit: true,
			article: {
				title: '',
				content: '',
				topImg: '',
				commentable: true,
				timing: false,
				createTime: '',
				tid: '',
				tags: '',
				description: '',
				pin: true
			},
			tags: [],
			types: [],
			inputVisible: false,
			inputValue: '',
			rules: {
				title: [
					{ required: true, message: '请输入标题', trigger: 'blur' },
					{ min: 3, max: 32, message: '长度在 3 到 5 个字符', trigger: 'blur' }
			    ],
				topImg: [
					{ required: true, message: '请输入封面图链接', trigger: 'change' }
				],
				tid: [
					{ required: true, message: '请选择文章分类', trigger: 'change' }
				],
				description: [
					{ required: true, message: '请对文章进行描述', trigger: 'change' }
				],
				tags: [
					{ required: true, message: '请选择文章标签', trigger: 'change' }
				]
			}
		}
	},
	methods: {
		getTypes(){
			let that = this
			this.axios.post('types').then(function(results){
				that.types = results.data
			},function(err){
				console.log(err);
			})
		},
		submitForm(formName) {
			this.$refs[formName].validate((valid) => {
			  if (valid) {
				this.saveArticle()
			  } else {
				console.log('error submit!!');
				return false;
			  }
			});
        },
		updateForm(formName){
			this.$refs[formName].validate((valid) => {
			  if (valid) {
				this.updateArticle()
			  } else {
				console.log('error submit!!');
				return false;
			  }
			});
		},
		saveArticle(){
			let that = this
			that.article.content = that.content
			for (let tag of that.tags) {
				if(that.article.tags==''){
					that.article.tags = tag
				}else{
					that.article.tags = that.article.tags + ',' + tag 
				}
			}
			this.axios.post('saveArticle',that.article).then(function(results){
				if(results.data){
					that.$notify({
						title: '成功',
						message: '发布成功',
						type: 'success'
					});
				}
				that.$router.push('/admin')
			},function(err){
				console.log(err);
			})
		},
		updateArticle(){
			let that = this
			that.article.content = that.content
			for (let tag of that.tags) {
				if(that.article.tags==''){
					that.article.tags = tag
				}else{
					that.article.tags = that.article.tags + ',' + tag 
				}
			}
			this.axios.post('updateArticle',that.article).then(function(results){
				if(results.data){
					that.$notify({
						title: '成功',
						message: '保存成功',
						type: 'success'
					});
				}
				that.$router.push('/admin')
			},function(err){
				console.log(err);
			})
		},
		handleClose(tag) {
			this.tags.splice(this.tags.indexOf(tag), 1);
		},
		showInput() {
			this.inputVisible = true;
			this.$nextTick(() => {
			  this.$refs.saveTagInput.$refs.input.focus();
			});
		},
		handleInputConfirm() {
			let inputValue = this.inputValue;
			if (inputValue) {
			  this.tags.push(inputValue);
			}
			this.inputVisible = false;
			this.inputValue = '';
		},
		initEdit(){
			this.edit = false
			this.article = this.$route.params.article
			this.content = this.article.md
			if(this.article.tags!=null){
				this.tags = this.article.tags.split(',')
			}
			this.article.tags = ''
		}
	},
	created() {
		this.$store.commit('showLoading')
		this.getTypes()
		if(this.$route.params.article){
			this.initEdit()
		}
	},
}
</script>

<style>
.mark-edit-form{
	text-align: start;
	width: 75%;
	margin: 0 auto;
	margin-top: 5em;
}
.mavonEditor {
  margin: 2em auto;
  margin-bottom: 3em;
  height: 700px;
}
.mark-edit-form .el-input{
	/* width: 100%; */
}
.mark-edit-form .textarea-desc .el-textarea__inner{
	min-height: 100px !important;
}
.mark-edit-form .article-btn{
	margin: 0 2em 0 0;
	display: inline-block;
}
.mark-edit-form .article-btn>.el-form-item__label{
	margin-right: .3em;
}
.mark-edit-form .article-btn>.el-form-item__content{
	display: inline-block;
}
.mark-edit-form .article-btn>.el-form-item__content>.el-switch{
	padding: 0 0 10px;
}
.mark-edit-form .article-submit-btn{
	text-align: right;
}
.el-tag + .el-tag {
    margin-left: 10px;
  }
  .button-new-tag {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
  }
  .input-new-tag {
    width: 90px;
    margin-left: 10px;
    vertical-align: bottom;
  }
 .article-type{
	 display: inline-block;
	 margin-right: 2em;
 }
</style>
